<template>
    <Section class="bg-dark">
        <div class="md:flex md:flex-row-reverse">
            <div class="md:w-3/12 mb-12 md:mb-0">
                <img class="w-full" src="/images/mock-reliable.png" />
                <!-- <img class="w-full" src="https://imgholder.ru/440x440/8493a8/adb9ca&text=IMAGE+HOLDER&font=kelson" /> -->
            </div>
            <div class="lg:w-1/12"></div>
            <div class="md:w-9/12 lg:w-8/12 md:pr-10">
                <div class="mb-12 text-sm md:text-base text-center md:text-left">
                    <h2 class="text-2xl md:text-2xl xl:text-4xl mb-6 lg:mb-12 text-white font-bold">Play casino games on reliable online casinos</h2>
                    <p class="block text-white mb-6 lg:mb-12">There are now hundreds of online casinos on the internet and unfortunately not all of them are equally reliable. It is not for nothing that the government wants to fully legalize the online casino market as soon as possible, so that you as a casino player are protected against this.</p>
                    <p class="block text-white mb-6 lg:mb-12">Of course we support the government in this, because we also want to protect you as a casino player. That is why you will only find reliable and safe online casinos with us. We test and assess the casinos on multiple characteristics. In addition, we select casinos that can have...</p>
                    <a class="underline" href="#">Read More</a>
                </div>
            </div>
        </div>
    </Section>
</template>