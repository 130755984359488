<template>
    <Section>
        <div class="md:flex">
            <div class="md:w-4/12 mb-12 md:mb-0">
                <img class="w-full" src="/images/goal.png" />
            </div>
            <div class="lg:w-1/12">
                
            </div>
            <div class="md:w-8/12 lg:w-7/12 md:pl-10">
                <div class="-mt-6 lg:mt-0 mb-12 text-sm md:text-base text-center md:text-left">
                    <h2 class="text-2xl md:text-2xl xl:text-4xl mb-6 lg:mb-12 text-dark font-bold">The goal of this guide</h2>
                    <p class="block mb-6 lg:mb-12">The goal of this site is to help you with honest information to have more fun playing casino games. There are many websites on the internet that have only one goal, to entice you to play in the casinos without helping you further. They often send you with incorrect information directly to unreliable casinos.</p>
                    <p class="block mb-6 lg:mb-12">Our approach is different. We give honest information, base the tips and strategies on mathematical principles and try to ensure that you win more often and can play casino games longer. Remember, the casinos always have an advantage with every game. But with the right tactics...</p>
                    <a class="underline" href="#">Read More</a>
                </div>
            </div>
        </div>
    </Section>
</template>