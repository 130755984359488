<script setup>
import { partnerLink } from "@/utils/links";
import CircleCheck from "@/Components/Icon/CircleCheck.vue";
// import casino from "@/data/top.json";

const props = defineProps({
    casino: Object,
})

const currentYear = new Date().getFullYear();
</script>

<template>
    <Section class="bg-dark overflow-hidden top-rated pb-52 md:pb-14">
        <div class="relative text-center mb-28 mt-8 md:mb-12 md:mt-10 z-10">
            <h2 class="text-2xl md:text-4xl text-white font-bold">Play in the top rated casino {{ currentYear }}</h2>
        </div>
        <div class="relative z-10 bg-white max-w-[932px] mx-auto shadow-brand rounded-lg pt-16 md:pt-8 pb-6 px-6">
            <div class="md:flex items-center gap-x-6 p-4">
                <div 
                    class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 md:relative md:left-auto md:top-auto md:rounded-none md:transform-none"
                >
                    <Marker class="-left-8 translate-y-1/3 md:top-0 md:-left-2.5 md:-translate-y-1/2" v-if="casino.marker">{{ casino.marker }}</Marker>
                    <picture>
                        <source media="(max-width: 768px)" :srcset="casino.logoSquare" />
                        <source media="(min-width: 769px)" :srcset="casino.logo" />
                        <img :src="casino.logo" class="block border rounded-full h-full object-fill aspect-square md:border-none md:rounded-md md:object-none md:aspect-auto max-w-[140px] md:max-w-[220px]" />
                    </picture>
                    <!-- <img class="block border rounded-full h-full object-fill aspect-square md:border-none md:rounded-md md:object-none md:aspect-auto max-w-[140px] md:max-w-[220px]" :src="casino.logo" :alt="casino.name" /> -->
                </div>
                <div class="flex-1 text-center md:text-left">
                    <h3 class="inline-block text-black text-xl font-bold md:font-medium md:truncate md:max-w-[200px]">{{ casino.name }}</h3>
                    <Rating :value="casino.ratingValue" :votes="casino.ratingVotes" />
                </div>
                <ul class="-mt-3">
                    <li 
                        class="flex items-start gap-2 mt-1 md:mt-3" 
                        v-for="item, key in casino.features"
                        :key="key"
                    >
                        <CircleCheck class="shrink-0 text-primary lg:mt-1" />
                        <span class="text-xs md:sm lg:text-base">{{ item.feature }}</span>
                    </li>
                </ul>
            </div>

            <div class="mt-4 text-center">
                <Button theme="primary" type="a" target="_blank" :href="partnerLink(casino.link)">Play now</Button>
            </div>
        </div>

        <img class="absolute bottom-0 left-0 z-0 max-w-[140%]" src="/images/top-rated-left.png" />
        <img class="absolute bottom-0 right-0 z-0 max-w-[140%]" src="/images/top-rated-right.png" />
    </Section>
</template>