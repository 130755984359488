<script setup>
import { ref } from 'vue';
import CasinoItem from './CasinoItem.vue'
import MethodsModal from '@/Components/Modal/MethodsModal.vue'

defineProps({
    casinos: {
        type: Array,
        required: true
    }
})

const modalOpened = ref(true);
const currentCasino = ref(null);
</script>

<template>
    <div class="relative -mt-8 md:mt-4 -mb-9 lg:-mb-16 shadow-block rounded-t-md rounded-b-2xl bg-white z-10 overflow-hidden border border-white">
        <div class="casino-list">
            <div class="casino-list__header">
                <div class="casino-list__header-item">#</div>
                <div class="casino-list__header-item">Casino</div>
                <div class="casino-list__header-item">Bonus Offer</div>
                <div class="casino-list__header-item">Deposit Methods</div>
                <div class="casino-list__header-item">Rating</div>
                <div class="casino-list__header-item"></div>
            </div>
            <CasinoItem 
                v-for="casino, key in casinos" 
                v-bind="{...casino, number: key + 1}" 
                :key="key" 
                @select="currentCasino = casino"
            />
        </div>
        <MethodsModal :open="!!currentCasino" :methods="currentCasino?.methods ?? []" @close="() => currentCasino = null">123</MethodsModal>
    </div>
</template>