<script setup>
import CircleCheck from "@/Components/Icon/CircleCheck.vue";

const currentMonthName = new Date().toLocaleString('en-us', { month: 'long' });
const currentYear = new Date().getFullYear()
</script>

<template>
    <header class="overflow-hidden">
        <!-- <srcset>
            <source media="(max-width: 768px)" srcset="/images/header-mobile.png" />
            <source media="(min-width: 769px)" srcset="/images/header.png" />
        </srcset> -->
        <picture>
            <source media="(max-width: 992px)" srcset="/images/header-mobile.png" />
            <source media="(min-width: 993px)" srcset="/images/header.png" />
            <img src="/images/header.png" class="block w-full" />
        </picture>
    </header>
    <div class="topline pb-8 lg:pb-3 pt-2">
        <Container class="text-white relative z-10">
            <div class="px-6 py-2 md:px-0 md:py-0">
                <h1 class="text-xl md:text-4xl uppercase">Best <span class="text-secondary">online casinos</span> {{ currentYear }}</h1>
                <p class="text-medium md:text-xl md:font-bold mt-1 mb-2">Last bonus update {{ currentMonthName }} {{ currentYear }}</p>
                <ul class="md:flex text-sm md:text-medium justify-between capitalize">
                    <li class="flex items-center mb-2">
                        <CircleCheck class="mr-4" />
                        <span>Rated by experts</span>
                    </li>
                    <li class="flex items-center mb-2">
                        <CircleCheck class="mr-4" />
                        <span>fast withdrawals</span>
                    </li>
                    <li class="flex items-center mb-2">
                        <CircleCheck class="mr-4" />
                        <span>Secured & trusted by millions players</span>
                    </li>
                </ul>
            </div>
        </Container>
    </div>
</template>