<script setup>
import { ref } from 'vue';

import Header from "@/Components/Section/Header.vue";
import Casino from "@/Components/Section/Casino.vue";
import TopRated from "@/Components/Section/TopRated.vue";
import Best from "@/Components/Section/Best.vue";
import Reliable from "@/Components/Section/Reliable.vue";
import Goal from "@/Components/Section/Goal.vue";
import Footer from "@/Components/Section/Footer.vue";

import TopCasinoModal from "@/Components/Modal/TopCasinoModal.vue";

const props = defineProps({
    casino: Array
})

const best = [props.casino[0] ?? null, props.casino[1] ?? null].filter(Boolean);
const topRated = props.casino[0] ?? null;

const topModalOpened = ref(false);

setTimeout(() => {
  topModalOpened.value = true;
}, 10 * 1000);

// setInterval(() => {
//   topModalOpened.value = true;
// }, 3 * 60 * 1000)
</script>

<template>
  <main class="text-main">
    <Header />
    <Casino :casino="casino" />
    <TopRated :casino="topRated" />
    <Best :casino="best" />
    <Reliable />
    <Goal />
    <Footer />

    <TopCasinoModal :casino="topRated" :open="topModalOpened" @close="() => topModalOpened = false" />
  </main>
</template>
