<script setup>
import { computed } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel, TransitionRoot } from '@headlessui/vue'

const props = defineProps({
    open: Boolean,
    methods: {
        type: Array,
        required: true,
        default: () => []
    }
})
// const cryptoMethods = props.methods.filter(method => crypto.includes(method));
// const fiatMethods = props.methods.filter(method => !crypto.includes(method));
const fiatMethods = computed(() => props.methods.filter(method => method.type == 'fiat'));
const cryptoMethods = computed(() => props.methods.filter(method => method.type == 'crypto'));

const tabs = computed(() => [
    {
        name: 'All',
        methods: fiatMethods.value
    },
    {
        name: 'Crypto',
        methods: cryptoMethods.value
    },
])

defineEmits(['close'])
</script>

<template>
    <Modal :open="open" @close="$emit('close', e)" dialogClass="max-w-4xl w-full">
        <div  class="">
            <TabGroup>
                <TabList class="flex justify-around text-sm bg-[#DBE4EE]">
                    <Tab 
                        class="flex-1 shrink-0 outline-none focus:outline-none"
                        v-slot="{ selected }"
                        v-for="({ name }) in tabs"
                        :key="name"
                    >
                        <button
                            :class="[
                            'text-main w-full flex-1 rounded-t-md py-4 text-sm font-medium leading-5',
                            'outline-none focus:outline-none',
                            selected
                                ? 'bg-white'
                                : 'hover:bg-white/[0.12]',
                            ]"
                        >
                            {{ name }}
                        </button>
                    </Tab>
                    <button class="shrink-0 px-6" @click.prevent="$emit('close')">✕</button>
                </TabList>
                <TabPanels>
                    <TabPanel 
                        v-for="({ methods, name }) in tabs"
                        :key="name"
                        v-slot="{ selected }" 
                        :unmount="false"
                    >
                        <TransitionRoot :show="selected" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0" :unmount="false" class="p-8 -mt-4 flex flex-wrap overflow-y-scroll">
                            <div
                                class="mt-4 flex items-center gap-4 w-full flex-start"
                                :class="name == 'Crypto' ? 'sm:basis-1/3 sm:max-w-[33.33333%]' : 'flex-1 max-w-[50%] sm:max-w-[33.33333%] basis-1/2 sm:basis-1/3'"
                                v-for="method in methods"
                                :key="method"
                            >
                                <!-- <div class="casino-list__item-methods-item"> -->
                                    <img :src="method.icon" class="w-10 h-10 shadow-md rounded-full" :alt="method.name" />
                                <!-- </div> -->
                                <span class="truncate pr-3 text-xs sm:text-base">{{ method.name }}</span>
                            </div>
                        </TransitionRoot>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    </Modal>
</template>