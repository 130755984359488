import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
// import Lens from '@/views/Lens'

export function registerViews(app) {
    // Manually register some views...
    const components = import.meta.glob('./components/**/*.vue', { eager: true })

    Object.entries(components).forEach(([path, definition]) => {
        // Get name of component, based on filename
        // "./components/Fruits.vue" will become "Fruits"
        const componentName = path.split('/').pop().replace(/\.\w+$/, '')

        // Register component on this Vue instance
        app.component(componentName, definition.default)
    })
}
